import * as React from 'react';

import Container from '../../../common/container';
import cryptoImg from '../../../assets/images/edunomic_class.jpg';
import PcIcon from '../../../assets/icons/pc.svg';

import * as s from './styles.module.scss';

const activitiesData = [
  {
    icon: <PcIcon />,
    text: 'Blockchain bootcamps for foster kids and other at-risk youth',
  },
  {
    icon: <PcIcon />,
    text: 'Financial Literacy training including saving, credit, and investments, for at-risk youth',
  },
];

const Activities = () => {
  return (
    <section className={s.activities}>
      <Container>
        <div className={s.activities_content}>
          <div className={s.activities_block}>
            <h3 className={s.activities_title}>Activities</h3>
            <div className={s.activities_wrapper}>
              {activitiesData?.map((activity) => (
                <div className={s.activities_info} key={activity.text}>
                  <div>{activity.icon}</div>
                  <p className={s.activities_text}>{activity.text}</p>
                </div>
              ))}
            </div>
          </div>
          <img className={s.activities_img} src={cryptoImg} alt="Crypto" />
        </div>
      </Container>
    </section>
  );
};

export default Activities;
