import stevePhoto from '../assets/images/founders/steve.png';
import alexPhoto from '../assets/images/founders/alex.png';
import jamesPhoto from '../assets/images/founders/james.png';

const FOUNDING_TEAM = [
  {
    img: stevePhoto,
    name: 'Steve Karam',
    position: 'Founder, Edunomic Academy',
  },
  { img: alexPhoto, name: 'Alex Messenger', position: 'COO, Edunomic Academy' },
  {
    img: jamesPhoto,
    name: 'James Dawson',
    position: 'SVP, Content Development',
  },
];

export { FOUNDING_TEAM };
