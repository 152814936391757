import * as React from 'react';

import Container from '../../../common/container';
import AboutCube from '../../../assets/icons/about-cube.svg';

import * as s from './styles.module.scss';

const Hero = () => {
  return (
    <section className={s.about}>
      <Container className={s.about_wrapper}>
        <div className={s.about_icon}>
          <AboutCube />
        </div>
        <h1 className={s.about_title}>About Edunomic</h1>
      </Container>
    </section>
  );
};

export default Hero;
