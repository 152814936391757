import * as React from 'react';

import Container from '../../../common/container';
import { FOUNDING_TEAM } from '../../../data/FOUNDING_TEAM';

import * as s from './styles.module.scss';

const FoundingTeam = () => {
  return (
    <section className={s.foundingTeam}>
      <Container>
        <div className={s.foundingTeam_content}>
          <h2 className={s.foundingTeam_title}>Founding Team</h2>
          <div className={s.foundingTeam_founders}>
            {FOUNDING_TEAM?.map((founder) => (
              <div className={s.foundingTeam_founder} key={founder.name}>
                <div className={s.foundingTeam_imgСontainer}>
                  <img src={founder.img} alt={founder.name} />
                </div>
                <div className={s.foundingTeam_about}>
                  <h5>{founder.name}</h5>
                  <p>{founder.position}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default FoundingTeam;
