import * as React from 'react';

import Layout from '../../layout';
import Hero from '../../components/about/hero';
import Mission from '../../components/about/mission';
import Activities from '../../components/about/activities';
import FoundingTeam from '../../components/about/founding-team';
import Supporters from '../../components/shared/supporters';

const AboutPage = () => {
  return (
    <Layout>
      <Hero />
      <Mission />
      <Activities />
      <FoundingTeam />
      <Supporters />
    </Layout>
  );
};

export default AboutPage;
