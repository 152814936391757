import * as React from 'react';

import Container from '../../../common/container';

import * as s from './styles.module.scss';

const Mission = () => {
  return (
    <section className={s.mission}>
      <Container>
        <div className={s.mission_content}>
          <h2 className={s.mission_title}>Mission</h2>
          <p className={s.mission_text}>
            Edunomic is focused on building a better, more intuitive blockchain
            built for learning, development, and bringing enterprise-grade EdTech
            tools into the blockchain space. Modern Education landscapes include
            many tools with a vast ecosystem of data, scheduling, and compliance
            technology. With Edunomic Chain our goal is to improve on this
            ecosystem with trusted, fast, and secure data storage on a blockchain
            built for EdTech; and more, to bring the worlds of Decentralized Finance,
            Education Technology, and Web3 together.
          </p>
        </div>
      </Container>
    </section>
  );
};

export default Mission;
